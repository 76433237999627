import { Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Header, Footer, BackToTop, ScrollToTop } from './components';
import routes from './data/routes';

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <Routes>
        {routes.map((route)=>(
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            element={route.element}
          />
        ))}
      </Routes>
      <Footer />
      <BackToTop />
    </div>
  );
}

export default App;
