import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

// Auth
export const login = async ({ email, password }) => {
    try {
        const res = await axios.post(
            `${baseUrl}api/v1/users/login`,
            { email, password },
            { headers: { 'Access-Control-Allow-Origin': '*' } });
        return res;
    } catch (err) {
        console.log(err.response);
        alert('登入失敗 (|||ﾟдﾟ)：請檢查帳號密碼是否正確！');
    }
};

// Portfolio
export const getPortfoliosFeatured = async () => {
    try {
        const res = await axios.get(`${baseUrl}api/v1/portfolios?isFeatured=true`);
        return res.data;
    } catch (err) {
        console.log(err.response.data);
    }
};

export const getPortfoliosByCategory = async ({ category }) => {
    try {
        const res = await axios.get(`${baseUrl}api/v1/portfolios/getPortfoliosByCategory/${category}`);
        return res.data;
    } catch (err) {
        console.log(err.response.data);
    }
};

export const getPortfolio = async ({ id }) => {
    try {
        const res = await axios.get(`${baseUrl}api/v1/portfolios/${id}`);
        return res.data;
    } catch (err) {
        console.log(err.response.data);
    }
};