import { HomePage, BlogPage, AboutPage, SigninPage } from '../pages';

const routes = [
    {
        path: '/',
        element: <HomePage />,
        exact: true,
        breadcrumbName: 'Home',
    },
    {
        path: '/blog',
        element: <BlogPage />,
        breadcrumbName: 'Blog',
    },
    {
        path: '/about',
        element: <AboutPage />,
        breadcrumbName: 'About',
    },
    {
        path: '/signin',
        element: <SigninPage />,
        breadcrumbName: 'Sign in',
    },
];

export default routes;
