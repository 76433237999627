import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from './SigninPage.module.css';
import { Loading } from '../../components';
import { loginAsync, selectStatus, selectLogin } from '../../redux/accountSlice';

export function SigninPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const { hasLogin } = useSelector(selectLogin);
    const loginStatus = useSelector(selectStatus);

    const handleSignin = async () => {
        if (email !== '' && password !== '') {
            setLoading(true);
            dispatch(loginAsync({ email, password }));
        } else {
            alert('-`д´- 請輸入帳號密碼！');
        }
    };

    useEffect(() => {
        if (loginStatus === 'error') {
            setLoading(false);
        } else if (loginStatus === 'idle') {
            setLoading(false);
        }
    }, [loginStatus]);

    return (
        <div className="container">
            {hasLogin && <Navigate to="/" replace={true} />}
            <div className={styles.page_title}>Sign in</div>
            <div className={styles.input_wrapper}>
                <input
                    type="email"
                    placeholder="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
                <input
                    type="password"
                    placeholder="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
                <button onClick={handleSignin}>Submit</button>
            </div>
            {loading && <Loading />}
        </div>
    );
}
