import React, { useEffect, useState } from 'react';
import styles from './HomePage.module.css';
import { YoutubeEmbed } from '../../components';

export function HomePage() {
    const [dot, setDot] = useState('section1');
    const sections = [
        {
            id: 'section1',
            name: 'logo',
        },
        {
            id: 'section2',
            name: 'blog',
        },
        {
            id: 'section3',
            name: 'youtube',
        },
    ];

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        if (window.scrollY <= windowHeight) {
            setDot('section1');
        } else if (window.scrollY > windowHeight && window.scrollY <= windowHeight * 2) {
            setDot('section2');
        } else if (window.scrollY > windowHeight * 2 && window.scrollY <= windowHeight * 3) {
            setDot('section3');
        } else {
            setDot('section4');
        }
    };

    const scrollToAnchor = (anchorName) => {
        if (anchorName) {
            const anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    return (
        <div className="container">
            <div className={styles.block_wrapper} id="section1">
                <div className={styles.logo_name}>adodaily</div>
            </div>
            <div className={styles.block_wrapper} id="section2">
                <div className={styles.page_title}>Blog</div>
                <div className={`${styles.content_wrapper} ${styles.blog}`}>
                    <div className={styles.blog_box}>Create</div>
                    <div className={styles.blog_box}>Life</div>
                    <div className={styles.blog_box}>Record</div>
                </div>
            </div>
            <div className={styles.block_wrapper} id="section3">
                <div className={styles.page_title}>YouTube</div>
                <div className={`${styles.content_wrapper} ${styles.youtube}`}>
                    <div className={styles.youtube_info_wrapper}>
                        <div className={styles.youtube_avatar_box}>
                            <img
                                src="https://yt3.ggpht.com/ytc/AKedOLTQOYtwyrcTu0yuevHB43xYTw8RTJ4tNGKfnC7y=s176-c-k-c0x00ffffff-no-rj"
                                alt="Youtube Avatar"
                                className={styles.youtube_avatar}
                            />
                        </div>
                        <div className={styles.youtube_name}>adodaily 阿豆日常</div>
                        <a
                            href="https://www.youtube.com/channel/UCPs6vWDYuFX5oGf7DA6VMhw?feature=emb_ch_name_ex"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.btn}
                        >
                            Subscribe
                        </a>
                    </div>
                    <div className={styles.youtube_video}>
                        <YoutubeEmbed embedId="Eic9w9aQwxs" />
                    </div>
                </div>
            </div>
            <div className={styles.dot_box}>
                {sections.map((item, index) => {
                    const dotStyle = {
                        backgroundColor: dot === item.id ? '#CB9F86' : 'transparent',
                        borderColor: dot === item.id ? '#CB9F86' : '#C4C4C4',
                    };
                    return (
                        <div
                            className={styles.dot}
                            style={dotStyle}
                            onClick={() => scrollToAnchor(item.id)}
                            key={index + item.name}
                        ></div>
                    );
                })}
            </div>
        </div>
    );
}
