import React from 'react';
import styles from './Footer.module.css';

export function Footer() {
    return (
        <div className={styles.footer_wrapper}>
            <div className={styles.footer_icon_wrapper}>
                <a
                    href="https://www.youtube.com/channel/UCPs6vWDYuFX5oGf7DA6VMhw/featured"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.footer_icon_box}
                >
                    <img className={styles.footer_icon} src="/img/icon_youtube.png" alt="icon" />
                </a>
                <a
                    href="https://www.instagram.com/adodaily/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.footer_icon_box}
                >
                    <img className={styles.footer_icon} src="/img/icon_instagram.png" alt="icon" />
                </a>
            </div>
            <div>© 2022 adodaily</div>
        </div>
    );
}
