import React from 'react';
import styles from './BlogPage.module.css';

export function BlogPage() {
    return (
        <div className="container">
            <div className={styles.page_title}>Blog</div>
            <div className={styles.page_subtitle}>⚠️ This page is still under development ⚠️</div>
        </div>
    );
}
